import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		api: {},
		user: {},
	},
	mutations: {
		crearAxios(state, token) {
			state.api = axios.create({
				baseURL: 'https://wsm.wuipi.net/api/',
				headers: {
					'Wuipi-Token': token
				}
			});
		},
		setUser(state, datos) {
			state.user = datos;
		}
	}
});
