import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.prototype.$api = 'https://wsm.wuipi.net/api/';

import './assets/css/spectre.min.css';
import './assets/css/spectre-icons.min.css';
import './assets/css/spectre-exp.min.css';

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
