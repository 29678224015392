<template>
	<div id="app">
		<transition name="fade" mode="out-in">
			<router-view />
		</transition>
	</div>
</template>

<script>
	export default {
		name: 'App',
		created() {
			if(Object.keys(this.$store.state.api).length === 0) {
				const token = localStorage.getItem('WuipiToken') == null
					? ''
					: localStorage.getItem('WuipiToken');
				this.$store.commit('crearAxios', token);
			}
		}
	}
</script>

<style>
	body {
		background-color: #eee !important;
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity .1s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
	.card {
		margin: .5rem;
		box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
	}
	.empty {
		margin-top: 1.5rem!important;
		box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
	}
</style>
