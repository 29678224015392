import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/ingresar',
		name: 'Ingresar',
		component: () => import('@/views/Ingresar.vue')
	},
	{
		path: '/',
		component: () => import('@/layouts/Dashboard.vue'),
		children: [{ name: 'Inicio', path: '', component: () => import('@/views/Inicio.vue') }],
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/perfil',
		component: () => import('@/layouts/Dashboard.vue'),
		children: [{ name: 'Perfil', path: '', component: () => import('@/views/Perfil.vue') }],
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/cliente/:id',
		component: () => import('@/layouts/Dashboard.vue'),
		children: [{ name: 'Cliente', path: '', component: () => import('@/views/Cliente.vue') }],
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/actividad/fotos/:id',
		component: () => import('@/layouts/Dashboard.vue'),
		children: [{ name: 'Fotos', path: '', component: () => import('@/views/Fotos.vue') }],
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/supervisor/ordenes',
		component: () => import('@/layouts/Dashboard.vue'),
		children: [{ name: 'SV_Ordenes', path: '', component: () => import('@/views/Supervisor/Ordenes.vue') }],
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/supervisor/orden/:id',
		component: () => import('@/layouts/Dashboard.vue'),
		children: [{ name: 'SV_Orden', path: '', component: () => import('@/views/Supervisor/Orden.vue') }],
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/supervisor/clientes',
		component: () => import('@/layouts/Dashboard.vue'),
		children: [{ name: 'SV_Clientes', path: '', component: () => import('@/views/Supervisor/Clientes.vue') }],
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/supervisor/servicios',
		component: () => import('@/layouts/Dashboard.vue'),
		children: [{ name: 'SV_Servicios', path: '', component: () => import('@/views/Supervisor/Servicios.vue') }],
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/supervisor/tecnicos',
		component: () => import('@/layouts/Dashboard.vue'),
		children: [{ name: 'SV_Tecnicos', path: '', component: () => import('@/views/Supervisor/Tecnicos.vue') }],
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/tecnico/ordenes',
		component: () => import('@/layouts/Dashboard.vue'),
		children: [{ name: 'TN_Ordenes', path: '', component: () => import('@/views/Tecnico/Ordenes.vue') }],
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/tecnico/orden/:id',
		component: () => import('@/layouts/Dashboard.vue'),
		children: [{ name: 'TN_Orden', path: '', component: () => import('@/views/Tecnico/Orden.vue') }],
		meta: {
			requiresAuth: true
		}
	},
];

const router = new VueRouter({
	routes
});

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (localStorage.getItem('WuipiToken') == null) {
			next('/ingresar');
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;
